import { HttpClient } from "@angular/common/http";
import { Injectable, computed, effect, inject, signal } from "@angular/core";
import type { IJwtData } from "@auth/IJwtData";
import type { IUser } from "@user/types/IUser";
import { jwtDecode } from "jwt-decode";
import { switchMap, tap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private http = inject(HttpClient);

	public $jwt = signal<string | null>(localStorage.getItem("jwt") ?? null);
	public $jwtData = computed<IJwtData | null>(() => {
		const jwt = this.$jwt();
		if (!jwt) {
			return null;
		}

		return jwtDecode(jwt);
	});
	public $isLogged = computed(() => !!this.$jwt());
	public $user = signal<IUser | null>(null);

	constructor() {
		effect(() => {
			localStorage.setItem("jwt", this.$jwt() ?? "");
		});
	}

	public login(email: string, password: string) {
		return this.http
			.post<string>(`${environment.apiUrl}/auth/credentials`, {
				username: email,
				password,
			})
			.pipe(
				tap((result) => this.$jwt.set(result)),
				switchMap(() => this.getLoggedUser()),
				tap((user) => this.$user.set(user)),
			);
	}

	public getLoggedUser() {
		return this.http.get<IUser>(`${environment.apiUrl}/auth/me`);
	}

	public logout() {
		this.$jwt.set(null);
		localStorage.removeItem("jwt");
	}
}
